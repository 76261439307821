@import '../fuse/scss/fuse.scss';
@import 'colors';

toolbar {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  z-index: 4;

  &.below {
    z-index: 2;
  }

  .mat-toolbar {
    position: relative;
    background: inherit !important;
    color: inherit !important;
  }

  .logo {
    display: flex;
    align-items: center;

    .logo-icon {
    }
  }

  .user-button,
  fuse-search-bar,
  .language-button,
  .chat-panel-toggle-button,
  .quick-panel-toggle-button {
    min-width: 64px;
    height: 64px;

    @include media-breakpoint('xs') {
      height: 56px;
    }
  }

  .navbar-toggle-button {
    min-width: 56px;
    height: 56px;
  }

  .toolbar-separator {
    height: 64px;
    width: 1px;

    @include media-breakpoint('xs') {
      height: 56px;
    }
  }

  .question-red {
    color: $red !important;
  }

  .question-blue {
    color: $blue !important;
  }

  .chat {
    background-color: lightgrey;
  }

  i.toolbar-icon, .mat-icon.toolbar-icon {
    color: #4a475e;
  }

  i.toolbar-icon {
    font-size: 20px;
  }

}

.language-button img.language-selection {
  margin-right: 24px;
  left: 3px;
  position: relative;
}
