@import '../fuse/scss/fuse.scss';

fuse-sidebar {
  &.navbar-fuse-sidebar {
    overflow: hidden;

    &.folded:not(.unfolded) {
      navbar {
        navbar-vertical-style-1 {
          .navbar-top {
            padding: 12px 0;
            justify-content: center;

            .buttons {
              display: none;
            }

            .logo {
              .logo-icon {
                margin: 0;
              }

              .logo-text {
                display: none;
              }
            }
          }

          .navbar-scroll-container {
            .user {
              padding: 12px 0;
              height: 64px;
              min-height: 64px;
              max-height: 64px;

              .avatar-container {
                position: relative;
                top: auto;
                padding: 0;
                transform: translateX(0);
                left: auto;

                .avatar {
                  width: 40px;
                  height: 40px;
                }
              }

              .username,
              .email {
                display: none;
              }
            }

            .navbar-content {
              padding-top: 0;

              // Material 2 specific style
              .material2 {
                .nav-item {
                  .nav-link {
                    border-radius: 20px;
                    margin: 0 12px;
                    padding: 0 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

navbar {
  &.vertical-style-1 {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;

    &.right-navbar {
      .toggle-sidebar-opened {
        mat-icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  navbar-vertical-style-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .navbar-top {
      display: flex;
      flex-direction: row;
      flex: 1 0 auto;
      align-items: center;
      justify-content: space-between;
      min-height: 64px;
      max-height: 64px;
      height: 64px;
      padding: 12px;
      max-width: 280px;

      @include media-breakpoint('xs') {
        min-height: 56px;
        max-height: 56px;
        height: 56px;
      }

      .logo {
        display: flex;
        align-items: center;

        .logo-icon {
          margin-right: 0.6rem;
        }

        .logo-text {
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0.4px;
          line-height: normal;
        }
      }

      .buttons {
        display: flex;
        align-items: center;
      }
    }

    .navbar-scroll-container {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      background: linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%);

      background-repeat: no-repeat;
      background-size: 100% 40px, 100% 10px;
      background-attachment: local, scroll;

      .user {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 136px;
        min-height: 136px;
        max-height: 136px;
        max-width: 280px;
        padding: 24px 0 64px 0;

        .h3.username {
          text-overflow: ellipsis;
          max-width: 70%;
          text-align: center;
        }

        .avatar-container {
          position: absolute;
          top: 92px;
          border-radius: 50%;
          padding: 8px;
          transform: translateX(-50%);
          left: 50%;
        }
      }

      .navbar-content {
        flex: 1 1 auto;
        padding-top: 32px;
      }
    }
  }
}
