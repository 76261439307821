navbar-horizontal-style-1 {

}

navbar {

  &.horizontal-style-1 {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    height: 56px;
    max-height: 56px;
    min-height: 56px;
  }
}
