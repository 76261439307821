quick-panel {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 380px;
  min-width: 280px;
  z-index: 99;

  .mat-slide-toggle-content {
    flex: 1;
  }
}
